import * as React from 'react';
import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';
import { cn } from '@/lib/utils';

import './card.css';

const cardVariants = cva(
  'card relative mb-auto border-t-[2px] border-l-[2px] border-black',
  {
    variants: {
      variant: {
        default: 'text-primary-foreground shadow',
        destructive: 'text-destructive-foreground shadow-sm',
        outline: 'border border-input shadow-sm',
        secondary: 'text-secondary-foreground shadow-sm  ',
        ghost: 'hover:text-accent-foreground',
        link: 'text-primary underline-offset-4 hover:underline',
      },
      size: {
        default: '',
        sm: 'rounded-md px-3 text-xs',
        lg: 'rounded-md px-8',
        icon: 'w-9',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  },
);



export interface CardProps
  extends React.ButtonHTMLAttributes<HTMLDivElement>,
    VariantProps<typeof cardVariants> {
  asChild?: boolean;
  shadowColor?: string;
}

const Card = React.forwardRef<HTMLDivElement, CardProps>(
  (
    {
      className,
      variant,
      size,
      asChild = false,
      shadowColor,
      ...props
    },
    ref,
  ) => {
    const Comp = asChild ? Slot : 'div';
    const style = {
      '--shadow-color': shadowColor || 'transparent',
      backgroundColor: shadowColor,
    };
    return (
      <Comp
        style={style}
        className={cn(cardVariants({ variant, size }), className)}
        ref={ref}
        {...props}
      />
    );
  },
);

export { Card, cardVariants };
